/* src/components/SongList.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Song from "./Song.svelte.js";
import Paginated from "./Paginated.svelte.js";

function create_default_slot(ctx) {
	let song;
	let current;

	song = new Song({
			props: {
				list: /*list*/ ctx[2],
				song: /*song*/ ctx[3],
				index: /*index*/ ctx[4]
			}
		});

	return {
		c() {
			create_component(song.$$.fragment);
		},
		m(target, anchor) {
			mount_component(song, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const song_changes = {};
			if (dirty & /*list*/ 4) song_changes.list = /*list*/ ctx[2];
			if (dirty & /*song*/ 8) song_changes.song = /*song*/ ctx[3];
			if (dirty & /*index*/ 16) song_changes.index = /*index*/ ctx[4];
			song.$set(song_changes);
		},
		i(local) {
			if (current) return;
			transition_in(song.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(song.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(song, detaching);
		}
	};
}

function create_fragment(ctx) {
	let paginated;
	let current;

	paginated = new Paginated({
			props: {
				list: /*list*/ ctx[2],
				startOffset: /*startOffset*/ ctx[0],
				maxItems: /*maxItems*/ ctx[1],
				$$slots: {
					default: [
						create_default_slot,
						({ item: song, list, index }) => ({ 3: song, 2: list, 4: index }),
						({ item: song, list, index }) => (song ? 8 : 0) | (list ? 4 : 0) | (index ? 16 : 0)
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(paginated.$$.fragment);
		},
		m(target, anchor) {
			mount_component(paginated, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const paginated_changes = {};
			if (dirty & /*list*/ 4) paginated_changes.list = /*list*/ ctx[2];
			if (dirty & /*startOffset*/ 1) paginated_changes.startOffset = /*startOffset*/ ctx[0];
			if (dirty & /*maxItems*/ 2) paginated_changes.maxItems = /*maxItems*/ ctx[1];

			if (dirty & /*$$scope, list, song, index*/ 60) {
				paginated_changes.$$scope = { dirty, ctx };
			}

			paginated.$set(paginated_changes);
		},
		i(local) {
			if (current) return;
			transition_in(paginated.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(paginated.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(paginated, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { list = [] } = $$props;
	let { startOffset = 0 } = $$props;
	let { maxItems = 100 } = $$props;

	$$self.$$set = $$props => {
		if ('list' in $$props) $$invalidate(2, list = $$props.list);
		if ('startOffset' in $$props) $$invalidate(0, startOffset = $$props.startOffset);
		if ('maxItems' in $$props) $$invalidate(1, maxItems = $$props.maxItems);
	};

	return [startOffset, maxItems, list];
}

class SongList extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { list: 2, startOffset: 0, maxItems: 1 });
	}
}

export default SongList;