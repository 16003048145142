import './LibraryView.svelte.css';
/* src/layouts/LibraryView.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import SongList from "../components/SongList.svelte.js";
import AlbumList from "../components/AlbumList.svelte.js";
import { library, albums } from "../logic/db.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (11:2) {#each ["Songs", "Albums"] as heading}
function create_each_block(ctx) {
	let h1;
	let t0;
	let t1;
	let mounted;
	let dispose;

	function click_handler(...args) {
		return /*click_handler*/ ctx[3](/*heading*/ ctx[4], ...args);
	}

	return {
		c() {
			h1 = element("h1");
			t0 = text(/*heading*/ ctx[4]);
			t1 = space();
			attr(h1, "tabindex", "0");
			attr(h1, "class", "svelte-19fa9mp");
			toggle_class(h1, "activeHeading", /*showing*/ ctx[0] == /*heading*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, h1, anchor);
			append(h1, t0);
			append(h1, t1);

			if (!mounted) {
				dispose = listen(h1, "click", click_handler);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*showing*/ 1) {
				toggle_class(h1, "activeHeading", /*showing*/ ctx[0] == /*heading*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(h1);
			mounted = false;
			dispose();
		}
	};
}

// (23:31) 
function create_if_block_1(ctx) {
	let albumlist;
	let current;
	albumlist = new AlbumList({ props: { list: /*$albums*/ ctx[2] } });

	return {
		c() {
			create_component(albumlist.$$.fragment);
		},
		m(target, anchor) {
			mount_component(albumlist, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const albumlist_changes = {};
			if (dirty & /*$albums*/ 4) albumlist_changes.list = /*$albums*/ ctx[2];
			albumlist.$set(albumlist_changes);
		},
		i(local) {
			if (current) return;
			transition_in(albumlist.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(albumlist.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(albumlist, detaching);
		}
	};
}

// (21:1) {#if showing == "Songs"}
function create_if_block(ctx) {
	let songlist;
	let current;
	songlist = new SongList({ props: { list: /*$library*/ ctx[1] } });

	return {
		c() {
			create_component(songlist.$$.fragment);
		},
		m(target, anchor) {
			mount_component(songlist, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const songlist_changes = {};
			if (dirty & /*$library*/ 2) songlist_changes.list = /*$library*/ ctx[1];
			songlist.$set(songlist_changes);
		},
		i(local) {
			if (current) return;
			transition_in(songlist.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(songlist.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(songlist, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let div0;
	let t;
	let current_block_type_index;
	let if_block;
	let current;
	let each_value = ["Songs", "Albums"];
	let each_blocks = [];

	for (let i = 0; i < 2; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const if_block_creators = [create_if_block, create_if_block_1];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*showing*/ ctx[0] == "Songs") return 0;
		if (/*showing*/ ctx[0] == "Albums") return 1;
		return -1;
	}

	if (~(current_block_type_index = select_block_type(ctx, -1))) {
		if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	}

	return {
		c() {
			div1 = element("div");
			div0 = element("div");

			for (let i = 0; i < 2; i += 1) {
				each_blocks[i].c();
			}

			t = space();
			if (if_block) if_block.c();
			attr(div0, "class", "collectionTypes svelte-19fa9mp");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);

			for (let i = 0; i < 2; i += 1) {
				each_blocks[i].m(div0, null);
			}

			append(div1, t);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].m(div1, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (dirty & /*showing*/ 1) {
				each_value = ["Songs", "Albums"];
				let i;

				for (i = 0; i < 2; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div0, null);
					}
				}

				for (; i < 2; i += 1) {
					each_blocks[i].d(1);
				}
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if (~current_block_type_index) {
					if_blocks[current_block_type_index].p(ctx, dirty);
				}
			} else {
				if (if_block) {
					group_outros();

					transition_out(if_blocks[previous_block_index], 1, 1, () => {
						if_blocks[previous_block_index] = null;
					});

					check_outros();
				}

				if (~current_block_type_index) {
					if_block = if_blocks[current_block_type_index];

					if (!if_block) {
						if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
						if_block.c();
					} else {
						if_block.p(ctx, dirty);
					}

					transition_in(if_block, 1);
					if_block.m(div1, null);
				} else {
					if_block = null;
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_each(each_blocks, detaching);

			if (~current_block_type_index) {
				if_blocks[current_block_type_index].d();
			}
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $library;
	let $albums;
	component_subscribe($$self, library, $$value => $$invalidate(1, $library = $$value));
	component_subscribe($$self, albums, $$value => $$invalidate(2, $albums = $$value));
	let showing = "Songs";
	const click_handler = (heading, _) => $$invalidate(0, showing = heading);
	return [showing, $library, $albums, click_handler];
}

class LibraryView extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default LibraryView;