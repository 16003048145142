import './Album.svelte.css';
/* src/components/Album.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import CoverImage from "./CoverImage.svelte.js";
import { servers } from "../logic/db.js";
import { authString } from "../logic/utils.js";
import { browse } from "../logic/stores.js";

function create_if_block_1(ctx) {
	let t0;
	let t1_value = /*album*/ ctx[0].year + "";
	let t1;

	return {
		c() {
			t0 = text("• ");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*album*/ 1 && t1_value !== (t1_value = /*album*/ ctx[0].year + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (26:37) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("songs");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (26:3) {#if album.tracks.length == 1}
function create_if_block(ctx) {
	let t;

	return {
		c() {
			t = text("song");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let div0;
	let coverimage;
	let t0;
	let div1;
	let h2;
	let t1_value = /*album*/ ctx[0].album + "";
	let t1;
	let t2;
	let p;
	let t3_value = /*album*/ ctx[0].artist + "";
	let t3;
	let t4;
	let t5;
	let t6_value = /*album*/ ctx[0].tracks.length + "";
	let t6;
	let t7;
	let current;
	let mounted;
	let dispose;
	coverimage = new CoverImage({ props: { item: /*album*/ ctx[0] } });
	let if_block0 = /*album*/ ctx[0].year && create_if_block_1(ctx);

	function select_block_type(ctx, dirty) {
		if (/*album*/ ctx[0].tracks.length == 1) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block1 = current_block_type(ctx);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			create_component(coverimage.$$.fragment);
			t0 = space();
			div1 = element("div");
			h2 = element("h2");
			t1 = text(t1_value);
			t2 = space();
			p = element("p");
			t3 = text(t3_value);
			t4 = space();
			if (if_block0) if_block0.c();
			t5 = text("\r\n\t\t\t• ");
			t6 = text(t6_value);
			t7 = space();
			if_block1.c();
			attr(div0, "class", "albumCover svelte-vk5m8n");
			attr(h2, "class", "svelte-vk5m8n");
			attr(p, "class", "svelte-vk5m8n");
			attr(div1, "class", "albumName svelte-vk5m8n");
			attr(div2, "class", "album svelte-vk5m8n");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);
			mount_component(coverimage, div0, null);
			append(div2, t0);
			append(div2, div1);
			append(div1, h2);
			append(h2, t1);
			append(div1, t2);
			append(div1, p);
			append(p, t3);
			append(p, t4);
			if (if_block0) if_block0.m(p, null);
			append(p, t5);
			append(p, t6);
			append(p, t7);
			if_block1.m(p, null);
			current = true;

			if (!mounted) {
				dispose = listen(div2, "click", /*click_handler*/ ctx[1]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			const coverimage_changes = {};
			if (dirty & /*album*/ 1) coverimage_changes.item = /*album*/ ctx[0];
			coverimage.$set(coverimage_changes);
			if ((!current || dirty & /*album*/ 1) && t1_value !== (t1_value = /*album*/ ctx[0].album + "")) set_data(t1, t1_value);
			if ((!current || dirty & /*album*/ 1) && t3_value !== (t3_value = /*album*/ ctx[0].artist + "")) set_data(t3, t3_value);

			if (/*album*/ ctx[0].year) {
				if (if_block0) {
					if_block0.p(ctx, dirty);
				} else {
					if_block0 = create_if_block_1(ctx);
					if_block0.c();
					if_block0.m(p, t5);
				}
			} else if (if_block0) {
				if_block0.d(1);
				if_block0 = null;
			}

			if ((!current || dirty & /*album*/ 1) && t6_value !== (t6_value = /*album*/ ctx[0].tracks.length + "")) set_data(t6, t6_value);

			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block1.d(1);
				if_block1 = current_block_type(ctx);

				if (if_block1) {
					if_block1.c();
					if_block1.m(p, null);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(coverimage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(coverimage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			destroy_component(coverimage);
			if (if_block0) if_block0.d();
			if_block1.d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { album } = $$props;

	const click_handler = _ => browse("album", "Album", {
		primaryKey: [album.album, album.artist, album.year]
	});

	$$self.$$set = $$props => {
		if ('album' in $$props) $$invalidate(0, album = $$props.album);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*album*/ 1) {
			$: console.log(album);
		}
	};

	return [album, click_handler];
}

class Album extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { album: 0 });
	}
}

export default Album;