import './Paginated.svelte.css';
/* src/components/Paginated.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	create_component,
	create_slot,
	destroy_component,
	destroy_each,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	group_outros,
	init,
	insert,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

import IconButton from "./IconButton.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	child_ctx[12] = i;
	return child_ctx;
}

const get_default_slot_changes = dirty => ({
	item: dirty & /*offsetList, startIndex, maxItems*/ 28,
	list: dirty & /*list*/ 1,
	index: dirty & /*startIndex, startOffset*/ 10
});

const get_default_slot_context = ctx => ({
	item: /*item*/ ctx[10],
	list: /*list*/ ctx[0],
	index: /*index*/ ctx[12] + /*startIndex*/ ctx[3] + /*startOffset*/ ctx[1]
});

// (13:1) {#if offsetList.length > maxItems}
function create_if_block(ctx) {
	let div;
	let p;
	let t0_value = /*startIndex*/ ctx[3] + 1 + "";
	let t0;
	let t1;
	let t2_value = Math.min(/*startIndex*/ ctx[3] + /*maxItems*/ ctx[2], /*offsetList*/ ctx[4].length) + "";
	let t2;
	let t3;
	let t4_value = /*offsetList*/ ctx[4].length + "";
	let t4;
	let t5;
	let iconbutton0;
	let t6;
	let iconbutton1;
	let current;
	iconbutton0 = new IconButton({ props: { icon: "arrow_back" } });
	iconbutton0.$on("click", /*click_handler*/ ctx[8]);
	iconbutton1 = new IconButton({ props: { icon: "arrow_forward" } });
	iconbutton1.$on("click", /*click_handler_1*/ ctx[9]);

	return {
		c() {
			div = element("div");
			p = element("p");
			t0 = text(t0_value);
			t1 = text("-");
			t2 = text(t2_value);
			t3 = text(" of\r\n\t\t\t\t");
			t4 = text(t4_value);
			t5 = space();
			create_component(iconbutton0.$$.fragment);
			t6 = space();
			create_component(iconbutton1.$$.fragment);
			attr(p, "class", "svelte-13dpdbd");
			attr(div, "class", "itemListControls svelte-13dpdbd");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, p);
			append(p, t0);
			append(p, t1);
			append(p, t2);
			append(p, t3);
			append(p, t4);
			append(div, t5);
			mount_component(iconbutton0, div, null);
			append(div, t6);
			mount_component(iconbutton1, div, null);
			current = true;
		},
		p(ctx, dirty) {
			if ((!current || dirty & /*startIndex*/ 8) && t0_value !== (t0_value = /*startIndex*/ ctx[3] + 1 + "")) set_data(t0, t0_value);
			if ((!current || dirty & /*startIndex, maxItems, offsetList*/ 28) && t2_value !== (t2_value = Math.min(/*startIndex*/ ctx[3] + /*maxItems*/ ctx[2], /*offsetList*/ ctx[4].length) + "")) set_data(t2, t2_value);
			if ((!current || dirty & /*offsetList*/ 16) && t4_value !== (t4_value = /*offsetList*/ ctx[4].length + "")) set_data(t4, t4_value);
		},
		i(local) {
			if (current) return;
			transition_in(iconbutton0.$$.fragment, local);
			transition_in(iconbutton1.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(iconbutton0.$$.fragment, local);
			transition_out(iconbutton1.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(iconbutton0);
			destroy_component(iconbutton1);
		}
	};
}

// (36:2) {#each (offsetList || []).slice(startIndex, startIndex + maxItems) as item, index}
function create_each_block(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[7].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[6], get_default_slot_context);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope, offsetList, startIndex, maxItems, list, startOffset*/ 95)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[6],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[6])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[6], dirty, get_default_slot_changes),
						get_default_slot_context
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let t;
	let div0;
	let current;
	let if_block = /*offsetList*/ ctx[4].length > /*maxItems*/ ctx[2] && create_if_block(ctx);
	let each_value = (/*offsetList*/ ctx[4] || []).slice(/*startIndex*/ ctx[3], /*startIndex*/ ctx[3] + /*maxItems*/ ctx[2]);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div1 = element("div");
			if (if_block) if_block.c();
			t = space();
			div0 = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(div0, "class", "items svelte-13dpdbd");
			attr(div1, "class", "itemList svelte-13dpdbd");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			if (if_block) if_block.m(div1, null);
			append(div1, t);
			append(div1, div0);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div0, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (/*offsetList*/ ctx[4].length > /*maxItems*/ ctx[2]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*offsetList, maxItems*/ 20) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div1, t);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}

			if (dirty & /*$$scope, offsetList, startIndex, maxItems, list, startOffset*/ 95) {
				each_value = (/*offsetList*/ ctx[4] || []).slice(/*startIndex*/ ctx[3], /*startIndex*/ ctx[3] + /*maxItems*/ ctx[2]);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div0, null);
					}
				}

				group_outros();

				for (i = each_value.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);

			for (let i = 0; i < each_value.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			transition_out(if_block);
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block) if_block.d();
			destroy_each(each_blocks, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { list = [] } = $$props;
	let { startOffset = 0 } = $$props;
	let { maxItems = 100 } = $$props;
	let interval = maxItems;
	let startIndex = 0;
	let offsetList = [];

	const click_handler = _ => {
		$$invalidate(3, startIndex = Math.max(0, startIndex - interval));
	};

	const click_handler_1 = _ => {
		if (startIndex + maxItems < offsetList.length) {
			$$invalidate(3, startIndex += interval);
		}
	};

	$$self.$$set = $$props => {
		if ('list' in $$props) $$invalidate(0, list = $$props.list);
		if ('startOffset' in $$props) $$invalidate(1, startOffset = $$props.startOffset);
		if ('maxItems' in $$props) $$invalidate(2, maxItems = $$props.maxItems);
		if ('$$scope' in $$props) $$invalidate(6, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*list, startOffset*/ 3) {
			$: $$invalidate(4, offsetList = list.slice(startOffset));
		}
	};

	return [
		list,
		startOffset,
		maxItems,
		startIndex,
		offsetList,
		interval,
		$$scope,
		slots,
		click_handler,
		click_handler_1
	];
}

class Paginated extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { list: 0, startOffset: 1, maxItems: 2 });
	}
}

export default Paginated;