import './Song.svelte.css';
/* src/components/Song.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	is_function,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	set_store_value,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { onMount } from "../../_snowpack/pkg/svelte.js";
import CoverImage from "./CoverImage.svelte.js";
import { servers } from "../logic/db.js";
import { playing, queue, queueIndex } from "../logic/stores.js";
import { authString } from "../logic/utils.js";

function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("play_arrow");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (43:3) {#if isPlaying}
function create_if_block_1(ctx) {
	let t;

	return {
		c() {
			t = text("equalizer");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (56:3) {#if song.year}
function create_if_block(ctx) {
	let t0;
	let t1_value = /*song*/ ctx[0].year + "";
	let t1;

	return {
		c() {
			t0 = text("• ");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*song*/ 1 && t1_value !== (t1_value = /*song*/ ctx[0].year + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let span;
	let t0;
	let div0;
	let coverimage;
	let t1;
	let div1;
	let h3;
	let t2_value = /*song*/ ctx[0].title + "";
	let t2;
	let t3;
	let p;
	let t4_value = /*song*/ ctx[0].artist + "";
	let t4;
	let t5;
	let t6_value = /*song*/ ctx[0].album + "";
	let t6;
	let t7;
	let current;
	let mounted;
	let dispose;

	function select_block_type(ctx, dirty) {
		if (/*isPlaying*/ ctx[2]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	coverimage = new CoverImage({ props: { item: /*song*/ ctx[0] } });
	let if_block1 = /*song*/ ctx[0].year && create_if_block(ctx);

	return {
		c() {
			div2 = element("div");
			span = element("span");
			if_block0.c();
			t0 = space();
			div0 = element("div");
			create_component(coverimage.$$.fragment);
			t1 = space();
			div1 = element("div");
			h3 = element("h3");
			t2 = text(t2_value);
			t3 = space();
			p = element("p");
			t4 = text(t4_value);
			t5 = text(" • ");
			t6 = text(t6_value);
			t7 = space();
			if (if_block1) if_block1.c();
			attr(span, "class", "material-icons playIndicator svelte-1hknpvy");
			attr(div0, "class", "albumCover svelte-1hknpvy");
			attr(h3, "class", "svelte-1hknpvy");
			attr(p, "class", "svelte-1hknpvy");
			attr(div1, "class", "songName svelte-1hknpvy");
			attr(div2, "class", "song svelte-1hknpvy");
			toggle_class(div2, "playing", /*isPlaying*/ ctx[2]);
			toggle_class(div2, "disabled", /*disabled*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, span);
			if_block0.m(span, null);
			append(div2, t0);
			append(div2, div0);
			mount_component(coverimage, div0, null);
			append(div2, t1);
			append(div2, div1);
			append(div1, h3);
			append(h3, t2);
			append(div1, t3);
			append(div1, p);
			append(p, t4);
			append(p, t5);
			append(p, t6);
			append(p, t7);
			if (if_block1) if_block1.m(p, null);
			current = true;

			if (!mounted) {
				dispose = listen(div2, "click", function () {
					if (is_function(!/*disabled*/ ctx[1] && /*playSong*/ ctx[3])) (!/*disabled*/ ctx[1] && /*playSong*/ ctx[3]).apply(this, arguments);
				});

				mounted = true;
			}
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;

			if (current_block_type !== (current_block_type = select_block_type(ctx, dirty))) {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(span, null);
				}
			}

			const coverimage_changes = {};
			if (dirty & /*song*/ 1) coverimage_changes.item = /*song*/ ctx[0];
			coverimage.$set(coverimage_changes);
			if ((!current || dirty & /*song*/ 1) && t2_value !== (t2_value = /*song*/ ctx[0].title + "")) set_data(t2, t2_value);
			if ((!current || dirty & /*song*/ 1) && t4_value !== (t4_value = /*song*/ ctx[0].artist + "")) set_data(t4, t4_value);
			if ((!current || dirty & /*song*/ 1) && t6_value !== (t6_value = /*song*/ ctx[0].album + "")) set_data(t6, t6_value);

			if (/*song*/ ctx[0].year) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block(ctx);
					if_block1.c();
					if_block1.m(p, null);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if (dirty & /*isPlaying*/ 4) {
				toggle_class(div2, "playing", /*isPlaying*/ ctx[2]);
			}

			if (dirty & /*disabled*/ 2) {
				toggle_class(div2, "disabled", /*disabled*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(coverimage.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(coverimage.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if_block0.d();
			destroy_component(coverimage);
			if (if_block1) if_block1.d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $queueIndex;
	let $queue;
	let $playing;
	component_subscribe($$self, queueIndex, $$value => $$invalidate(8, $queueIndex = $$value));
	component_subscribe($$self, queue, $$value => $$invalidate(9, $queue = $$value));
	component_subscribe($$self, playing, $$value => $$invalidate(6, $playing = $$value));
	let { song } = $$props;
	let { list } = $$props;
	let { index } = $$props;
	let { disabled = false } = $$props;
	let isPlaying = false;

	function playSong() {
		set_store_value(queue, $queue = [...list], $queue);
		set_store_value(queueIndex, $queueIndex = index, $queueIndex);
	}

	let songImage;

	onMount(_ => {
		console.log("song mounted");
	});

	let loaded = false;

	$$self.$$set = $$props => {
		if ('song' in $$props) $$invalidate(0, song = $$props.song);
		if ('list' in $$props) $$invalidate(4, list = $$props.list);
		if ('index' in $$props) $$invalidate(5, index = $$props.index);
		if ('disabled' in $$props) $$invalidate(1, disabled = $$props.disabled);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$playing, song*/ 65) {
			$: $playing && $$invalidate(2, isPlaying = $playing.server == song.server && $playing.mediaUrl == song.mediaUrl);
		}

		if ($$self.$$.dirty & /*song*/ 1) {
			$: {
				song;
				loaded = false;
			}
		}
	};

	return [song, disabled, isPlaying, playSong, list, index, $playing];
}

class Song extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { song: 0, list: 4, index: 5, disabled: 1 });
	}
}

export default Song;