import './IconButton.svelte.css';
/* src/components/IconButton.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	assign,
	attr,
	check_outros,
	create_slot,
	detach,
	element,
	exclude_internal_props,
	get_all_dirty_from_scope,
	get_slot_changes,
	get_spread_update,
	group_outros,
	init,
	insert,
	listen,
	safe_not_equal,
	set_attributes,
	set_data,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

import { createEventDispatcher } from "../../_snowpack/pkg/svelte.js";

function create_else_block(ctx) {
	let current;
	const default_slot_template = /*#slots*/ ctx[6].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[5], null);

	return {
		c() {
			if (default_slot) default_slot.c();
		},
		m(target, anchor) {
			if (default_slot) {
				default_slot.m(target, anchor);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 32)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[5],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[5])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[5], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (default_slot) default_slot.d(detaching);
		}
	};
}

// (13:1) {#if autoHide}
function create_if_block(ctx) {
	let div;
	let current;
	const default_slot_template = /*#slots*/ ctx[6].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[5], null);

	return {
		c() {
			div = element("div");
			if (default_slot) default_slot.c();
			attr(div, "class", "hideOnMobile svelte-uu7cmx");
		},
		m(target, anchor) {
			insert(target, div, anchor);

			if (default_slot) {
				default_slot.m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 32)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[5],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[5])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[5], dirty, null),
						null
					);
				}
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function create_fragment(ctx) {
	let button;
	let span;
	let t0;
	let t1;
	let current_block_type_index;
	let if_block;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*autoHide*/ ctx[1]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
	let button_levels = [/*props*/ ctx[3]];
	let button_data = {};

	for (let i = 0; i < button_levels.length; i += 1) {
		button_data = assign(button_data, button_levels[i]);
	}

	return {
		c() {
			button = element("button");
			span = element("span");
			t0 = text(/*icon*/ ctx[0]);
			t1 = space();
			if_block.c();
			attr(span, "class", "material-icons");
			set_attributes(button, button_data);
			toggle_class(button, "accent", /*accent*/ ctx[2]);
			toggle_class(button, "svelte-uu7cmx", true);
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, span);
			append(span, t0);
			append(button, t1);
			if_blocks[current_block_type_index].m(button, null);
			if (button.autofocus) button.focus();
			current = true;

			if (!mounted) {
				dispose = listen(button, "click", /*click_handler*/ ctx[7]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*icon*/ 1) set_data(t0, /*icon*/ ctx[0]);
			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(button, null);
			}

			set_attributes(button, button_data = get_spread_update(button_levels, [dirty & /*props*/ 8 && /*props*/ ctx[3]]));
			toggle_class(button, "accent", /*accent*/ ctx[2]);
			toggle_class(button, "svelte-uu7cmx", true);
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(button);
			if_blocks[current_block_type_index].d();
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { icon } = $$props;
	const dispatch = createEventDispatcher();
	let { autoHide = false } = $$props;
	let props;
	let { accent = false } = $$props;
	const click_handler = _ => dispatch("click");

	$$self.$$set = $$new_props => {
		$$invalidate(8, $$props = assign(assign({}, $$props), exclude_internal_props($$new_props)));
		if ('icon' in $$new_props) $$invalidate(0, icon = $$new_props.icon);
		if ('autoHide' in $$new_props) $$invalidate(1, autoHide = $$new_props.autoHide);
		if ('accent' in $$new_props) $$invalidate(2, accent = $$new_props.accent);
		if ('$$scope' in $$new_props) $$invalidate(5, $$scope = $$new_props.$$scope);
	};

	$$self.$$.update = () => {
		$: $$invalidate(3, props = {
			style: $$props.style,
			class: $$props.class
		});
	};

	$$props = exclude_internal_props($$props);
	return [icon, autoHide, accent, props, dispatch, $$scope, slots, click_handler];
}

class IconButton extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { icon: 0, autoHide: 1, accent: 2 });
	}
}

export default IconButton;