import './Prompt.svelte.css';
/* src/components/Prompt.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	binding_callbacks,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	space,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import IconButton from "./IconButton.svelte.js";

function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text(/*submit*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*submit*/ 2) set_data(t, /*submit*/ ctx[1]);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let input;
	let input_type_value;
	let t;
	let iconbutton;
	let current;
	let mounted;
	let dispose;

	iconbutton = new IconButton({
			props: {
				icon: /*submitIcon*/ ctx[2],
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	iconbutton.$on("click", /*click_handler*/ ctx[8]);

	return {
		c() {
			div = element("div");
			input = element("input");
			t = space();
			create_component(iconbutton.$$.fragment);
			attr(input, "type", input_type_value = /*password*/ ctx[4] ? "password" : "text");
			attr(input, "placeholder", /*prompt*/ ctx[0]);
			attr(div, "class", "svelte-1y7nnnh");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			/*input_binding*/ ctx[7](input);
			append(div, t);
			mount_component(iconbutton, div, null);
			current = true;

			if (!mounted) {
				dispose = listen(input, "keydown", /*keydown_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (!current || dirty & /*password*/ 16 && input_type_value !== (input_type_value = /*password*/ ctx[4] ? "password" : "text")) {
				attr(input, "type", input_type_value);
			}

			if (!current || dirty & /*prompt*/ 1) {
				attr(input, "placeholder", /*prompt*/ ctx[0]);
			}

			const iconbutton_changes = {};
			if (dirty & /*submitIcon*/ 4) iconbutton_changes.icon = /*submitIcon*/ ctx[2];

			if (dirty & /*$$scope, submit*/ 514) {
				iconbutton_changes.$$scope = { dirty, ctx };
			}

			iconbutton.$set(iconbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(iconbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(iconbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			/*input_binding*/ ctx[7](null);
			destroy_component(iconbutton);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { prompt } = $$props;
	let { submit = "" } = $$props;
	let { submitIcon = "arrow_forward" } = $$props;

	let { onSubmit = function () {
		
	} } = $$props;

	let { password = false } = $$props;
	let inputElem;

	const keydown_handler = e => {
		if (e.key === "Enter") onSubmit(inputElem.value);
	};

	function input_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			inputElem = $$value;
			$$invalidate(5, inputElem);
		});
	}

	const click_handler = _ => onSubmit(inputElem.value);

	$$self.$$set = $$props => {
		if ('prompt' in $$props) $$invalidate(0, prompt = $$props.prompt);
		if ('submit' in $$props) $$invalidate(1, submit = $$props.submit);
		if ('submitIcon' in $$props) $$invalidate(2, submitIcon = $$props.submitIcon);
		if ('onSubmit' in $$props) $$invalidate(3, onSubmit = $$props.onSubmit);
		if ('password' in $$props) $$invalidate(4, password = $$props.password);
	};

	return [
		prompt,
		submit,
		submitIcon,
		onSubmit,
		password,
		inputElem,
		keydown_handler,
		input_binding,
		click_handler
	];
}

class Prompt extends SvelteComponent {
	constructor(options) {
		super();

		init(this, options, instance, create_fragment, safe_not_equal, {
			prompt: 0,
			submit: 1,
			submitIcon: 2,
			onSubmit: 3,
			password: 4
		});
	}
}

export default Prompt;