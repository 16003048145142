import './CoverImage.svelte.css';
/* src/components/CoverImage.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	attr,
	binding_callbacks,
	component_subscribe,
	detach,
	element,
	empty,
	init,
	insert,
	listen,
	noop,
	safe_not_equal,
	src_url_equal,
	toggle_class
} from "../../_snowpack/pkg/svelte/internal.js";

import { authString } from "../logic/utils.js";
import { servers } from "../logic/db.js";

function create_else_block(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = "album.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "class", "albumCover default svelte-1ute9");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (21:0) {#if item.coverUrl && authString($servers, item)}
function create_if_block(ctx) {
	let img;
	let img_src_value;
	let mounted;
	let dispose;

	return {
		c() {
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = /*item*/ ctx[0].server + /*item*/ ctx[0].coverUrl + "?auth=" + authString(/*$servers*/ ctx[3], /*item*/ ctx[0]))) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "loading", "lazy");
			attr(img, "class", "albumCover svelte-1ute9");
			toggle_class(img, "hidden", !/*loaded*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, img, anchor);
			/*img_binding*/ ctx[5](img);

			if (!mounted) {
				dispose = listen(img, "load", /*load_handler*/ ctx[6]);
				mounted = true;
			}
		},
		p(ctx, dirty) {
			if (dirty & /*item, $servers*/ 9 && !src_url_equal(img.src, img_src_value = /*item*/ ctx[0].server + /*item*/ ctx[0].coverUrl + "?auth=" + authString(/*$servers*/ ctx[3], /*item*/ ctx[0]))) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*loaded*/ 2) {
				toggle_class(img, "hidden", !/*loaded*/ ctx[1]);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
			/*img_binding*/ ctx[5](null);
			mounted = false;
			dispose();
		}
	};
}

function create_fragment(ctx) {
	let show_if;
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (show_if == null || dirty & /*item, $servers*/ 9) show_if = !!(/*item*/ ctx[0].coverUrl && authString(/*$servers*/ ctx[3], /*item*/ ctx[0]));
		if (show_if) return create_if_block;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $servers;
	component_subscribe($$self, servers, $$value => $$invalidate(3, $servers = $$value));
	let { item } = $$props;
	let loaded = false;
	let lastCoverUrl;
	let coverImage;

	setInterval(
		() => {
			if (coverImage && coverImage.complete) {
				$$invalidate(1, loaded = true);
			}
		},
		1000
	);

	function img_binding($$value) {
		binding_callbacks[$$value ? 'unshift' : 'push'](() => {
			coverImage = $$value;
			$$invalidate(2, coverImage);
		});
	}

	const load_handler = _ => $$invalidate(1, loaded = true);

	$$self.$$set = $$props => {
		if ('item' in $$props) $$invalidate(0, item = $$props.item);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*item, lastCoverUrl*/ 17) {
			$: {
				if (item.coverUrl != lastCoverUrl) {
					$$invalidate(1, loaded = false);
				}

				$$invalidate(4, lastCoverUrl = item.coverUrl);
			}
		}
	};

	return [item, loaded, coverImage, $servers, lastCoverUrl, img_binding, load_handler];
}

class CoverImage extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { item: 0 });
	}
}

export default CoverImage;