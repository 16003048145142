/* src/add-ons/Lyrics.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "../../_snowpack/pkg/svelte/internal.js";

import { playing } from "../logic/stores.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[4] = list[i];
	return child_ctx;
}

// (27:0) {#if hasLyrics}
function create_if_block(ctx) {
	let div;
	let h1;
	let t1;
	let p;
	let each_value = /*lyrics*/ ctx[1].trim().split("\n").slice(/*lyrics*/ ctx[1].trim().indexOf("Paroles") == 0 ? 1 : 0);
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			div = element("div");
			h1 = element("h1");
			h1.textContent = "Lyrics";
			t1 = space();
			p = element("p");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h1);
			append(div, t1);
			append(div, p);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(p, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*lyrics*/ 2) {
				each_value = /*lyrics*/ ctx[1].trim().split("\n").slice(/*lyrics*/ ctx[1].trim().indexOf("Paroles") == 0 ? 1 : 0);
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(p, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (31:3) {#each lyrics      .trim()      .split("\n")      .slice(lyrics.trim().indexOf("Paroles") == 0 ? 1 : 0) as line}
function create_each_block(ctx) {
	let t_value = /*line*/ ctx[4] + "";
	let t;
	let br;

	return {
		c() {
			t = text(t_value);
			br = element("br");
		},
		m(target, anchor) {
			insert(target, t, anchor);
			insert(target, br, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*lyrics*/ 2 && t_value !== (t_value = /*line*/ ctx[4] + "")) set_data(t, t_value);
		},
		d(detaching) {
			if (detaching) detach(t);
			if (detaching) detach(br);
		}
	};
}

function create_fragment(ctx) {
	let if_block_anchor;
	let if_block = /*hasLyrics*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			if (if_block) if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, [dirty]) {
			if (/*hasLyrics*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(if_block_anchor.parentNode, if_block_anchor);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $playing;
	component_subscribe($$self, playing, $$value => $$invalidate(2, $playing = $$value));
	let hasLyrics = false;
	let lyrics = "";

	async function fetchLyrics(song) {
		// fetch lyrics from lyrics.ovh
		if (!song) {
			return;
		}

		$$invalidate(0, hasLyrics = false);
		const response = await fetch(`https://api.lyrics.ovh/v1/${song.artist}/${song.title}`);
		const data = await response.json();
		console.log(data);

		if (data.lyrics) {
			$$invalidate(0, hasLyrics = true);
			$$invalidate(1, lyrics = data.lyrics);
		} else {
			$$invalidate(0, hasLyrics = false);
			$$invalidate(1, lyrics = "");
		}
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$playing*/ 4) {
			$: fetchLyrics($playing);
		}
	};

	return [hasLyrics, lyrics, $playing];
}

class Lyrics extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Lyrics;