/* src/layouts/ArtistView.svelte generated by Svelte v3.44.2 */
import { SvelteComponent, init, safe_not_equal } from "../../_snowpack/pkg/svelte/internal.js";

class ArtistView extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, null, safe_not_equal, {});
	}
}

export default ArtistView;