/* src/components/AlbumList.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	create_component,
	destroy_component,
	init,
	mount_component,
	safe_not_equal,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import Album from "./Album.svelte.js";
import Paginated from "./Paginated.svelte.js";

function create_default_slot(ctx) {
	let album;
	let current;
	album = new Album({ props: { album: /*album*/ ctx[2] } });

	return {
		c() {
			create_component(album.$$.fragment);
		},
		m(target, anchor) {
			mount_component(album, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const album_changes = {};
			if (dirty & /*album*/ 4) album_changes.album = /*album*/ ctx[2];
			album.$set(album_changes);
		},
		i(local) {
			if (current) return;
			transition_in(album.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(album.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(album, detaching);
		}
	};
}

function create_fragment(ctx) {
	let paginated;
	let current;

	paginated = new Paginated({
			props: {
				list: /*list*/ ctx[0],
				maxItems: /*maxItems*/ ctx[1],
				$$slots: {
					default: [
						create_default_slot,
						({ item: album }) => ({ 2: album }),
						({ item: album }) => album ? 4 : 0
					]
				},
				$$scope: { ctx }
			}
		});

	return {
		c() {
			create_component(paginated.$$.fragment);
		},
		m(target, anchor) {
			mount_component(paginated, target, anchor);
			current = true;
		},
		p(ctx, [dirty]) {
			const paginated_changes = {};
			if (dirty & /*list*/ 1) paginated_changes.list = /*list*/ ctx[0];
			if (dirty & /*maxItems*/ 2) paginated_changes.maxItems = /*maxItems*/ ctx[1];

			if (dirty & /*$$scope, album*/ 12) {
				paginated_changes.$$scope = { dirty, ctx };
			}

			paginated.$set(paginated_changes);
		},
		i(local) {
			if (current) return;
			transition_in(paginated.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(paginated.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(paginated, detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { list = [] } = $$props;
	let { maxItems = 100 } = $$props;

	$$self.$$set = $$props => {
		if ('list' in $$props) $$invalidate(0, list = $$props.list);
		if ('maxItems' in $$props) $$invalidate(1, maxItems = $$props.maxItems);
	};

	return [list, maxItems];
}

class AlbumList extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { list: 0, maxItems: 1 });
	}
}

export default AlbumList;