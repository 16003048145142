import './GroupPlayback.svelte.css';
/* src/add-ons/GroupPlayback.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	run_all,
	safe_not_equal,
	set_data,
	set_input_value,
	set_style,
	space,
	text,
	toggle_class,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import IconButton from "../components/IconButton.svelte.js";
import { queue, playing, queueIndex, playState } from "../logic/stores.js";
import { persistent } from "../logic/utils.js";
import Paho from "../../_snowpack/pkg/paho-mqtt.js";
import { onMount } from "../../_snowpack/pkg/svelte.js";

function create_else_block(ctx) {
	let iconbutton;
	let current;

	iconbutton = new IconButton({
			props: {
				icon: "wifi",
				$$slots: { default: [create_default_slot_1] },
				$$scope: { ctx }
			}
		});

	iconbutton.$on("click", /*connect*/ ctx[12]);

	return {
		c() {
			create_component(iconbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(iconbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const iconbutton_changes = {};

			if (dirty & /*$$scope*/ 1073741824) {
				iconbutton_changes.$$scope = { dirty, ctx };
			}

			iconbutton.$set(iconbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(iconbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(iconbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(iconbutton, detaching);
		}
	};
}

// (146:3) {#if connected}
function create_if_block(ctx) {
	let iconbutton;
	let current;

	iconbutton = new IconButton({
			props: {
				icon: "wifi",
				$$slots: { default: [create_default_slot] },
				$$scope: { ctx }
			}
		});

	iconbutton.$on("click", /*disconnect*/ ctx[13]);

	return {
		c() {
			create_component(iconbutton.$$.fragment);
		},
		m(target, anchor) {
			mount_component(iconbutton, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const iconbutton_changes = {};

			if (dirty & /*$$scope*/ 1073741824) {
				iconbutton_changes.$$scope = { dirty, ctx };
			}

			iconbutton.$set(iconbutton_changes);
		},
		i(local) {
			if (current) return;
			transition_in(iconbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(iconbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(iconbutton, detaching);
		}
	};
}

// (149:4) <IconButton icon="wifi" on:click={connect}>
function create_default_slot_1(ctx) {
	let t;

	return {
		c() {
			t = text("Connect");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (147:4) <IconButton icon="wifi" on:click={disconnect}>
function create_default_slot(ctx) {
	let t;

	return {
		c() {
			t = text("Disconnect");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

function create_fragment(ctx) {
	let div2;
	let h1;
	let t1;
	let div1;
	let input0;
	let t2;
	let input1;
	let t3;
	let input2;
	let t4;
	let input3;
	let t5;
	let div0;
	let current_block_type_index;
	let if_block;
	let t6;
	let p;
	let t7;
	let t8;
	let input4;
	let current;
	let mounted;
	let dispose;
	const if_block_creators = [create_if_block, create_else_block];
	const if_blocks = [];

	function select_block_type(ctx, dirty) {
		if (/*connected*/ ctx[1]) return 0;
		return 1;
	}

	current_block_type_index = select_block_type(ctx, -1);
	if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);

	return {
		c() {
			div2 = element("div");
			h1 = element("h1");
			h1.textContent = "Remote/Group Playback";
			t1 = space();
			div1 = element("div");
			input0 = element("input");
			t2 = space();
			input1 = element("input");
			t3 = space();
			input2 = element("input");
			t4 = space();
			input3 = element("input");
			t5 = space();
			div0 = element("div");
			if_block.c();
			t6 = space();
			p = element("p");
			t7 = text(/*message*/ ctx[2]);
			t8 = space();
			input4 = element("input");
			attr(input0, "class", "notWhileConnected svelte-1hzhh6r");
			attr(input0, "type", "text");
			attr(input0, "placeholder", "MQTT Server");
			attr(input1, "class", "notWhileConnected svelte-1hzhh6r");
			attr(input1, "type", "text");
			attr(input1, "placeholder", "Username");
			attr(input2, "class", "notWhileConnected svelte-1hzhh6r");
			attr(input2, "type", "password");
			attr(input2, "placeholder", "Password");
			attr(input3, "class", "notWhileConnected svelte-1hzhh6r");
			attr(input3, "type", "text");
			attr(input3, "placeholder", "Subscribe");
			set_style(p, "margin", "0");
			attr(div0, "class", "horizPanel svelte-1hzhh6r");
			attr(input4, "type", "text");
			attr(input4, "placeholder", "Publish");
			attr(input4, "class", "svelte-1hzhh6r");
			attr(div1, "class", "optionsGrid svelte-1hzhh6r");
			attr(div2, "class", "svelte-1hzhh6r");
			toggle_class(div2, "connected", /*connected*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, h1);
			append(div2, t1);
			append(div2, div1);
			append(div1, input0);
			set_input_value(input0, /*$server*/ ctx[6]);
			append(div1, t2);
			append(div1, input1);
			set_input_value(input1, /*$username*/ ctx[4]);
			append(div1, t3);
			append(div1, input2);
			set_input_value(input2, /*$password*/ ctx[3]);
			append(div1, t4);
			append(div1, input3);
			set_input_value(input3, /*$sub*/ ctx[5]);
			append(div1, t5);
			append(div1, div0);
			if_blocks[current_block_type_index].m(div0, null);
			append(div0, t6);
			append(div0, p);
			append(p, t7);
			append(div1, t8);
			append(div1, input4);
			set_input_value(input4, /*$pub*/ ctx[0]);
			current = true;

			if (!mounted) {
				dispose = [
					listen(input0, "input", /*input0_input_handler*/ ctx[17]),
					listen(input1, "input", /*input1_input_handler*/ ctx[18]),
					listen(input2, "input", /*input2_input_handler*/ ctx[19]),
					listen(input3, "input", /*input3_input_handler*/ ctx[20]),
					listen(input4, "input", /*input4_input_handler*/ ctx[21])
				];

				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*$server*/ 64 && input0.value !== /*$server*/ ctx[6]) {
				set_input_value(input0, /*$server*/ ctx[6]);
			}

			if (dirty & /*$username*/ 16 && input1.value !== /*$username*/ ctx[4]) {
				set_input_value(input1, /*$username*/ ctx[4]);
			}

			if (dirty & /*$password*/ 8 && input2.value !== /*$password*/ ctx[3]) {
				set_input_value(input2, /*$password*/ ctx[3]);
			}

			if (dirty & /*$sub*/ 32 && input3.value !== /*$sub*/ ctx[5]) {
				set_input_value(input3, /*$sub*/ ctx[5]);
			}

			let previous_block_index = current_block_type_index;
			current_block_type_index = select_block_type(ctx, dirty);

			if (current_block_type_index === previous_block_index) {
				if_blocks[current_block_type_index].p(ctx, dirty);
			} else {
				group_outros();

				transition_out(if_blocks[previous_block_index], 1, 1, () => {
					if_blocks[previous_block_index] = null;
				});

				check_outros();
				if_block = if_blocks[current_block_type_index];

				if (!if_block) {
					if_block = if_blocks[current_block_type_index] = if_block_creators[current_block_type_index](ctx);
					if_block.c();
				} else {
					if_block.p(ctx, dirty);
				}

				transition_in(if_block, 1);
				if_block.m(div0, t6);
			}

			if (!current || dirty & /*message*/ 4) set_data(t7, /*message*/ ctx[2]);

			if (dirty & /*$pub*/ 1 && input4.value !== /*$pub*/ ctx[0]) {
				set_input_value(input4, /*$pub*/ ctx[0]);
			}

			if (dirty & /*connected*/ 2) {
				toggle_class(div2, "connected", /*connected*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if_blocks[current_block_type_index].d();
			mounted = false;
			run_all(dispose);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $password;
	let $username;
	let $sub;
	let $playState;
	let $queueIndex;
	let $queue;
	let $server;
	let $pub;
	component_subscribe($$self, playState, $$value => $$invalidate(14, $playState = $$value));
	component_subscribe($$self, queueIndex, $$value => $$invalidate(15, $queueIndex = $$value));
	component_subscribe($$self, queue, $$value => $$invalidate(16, $queue = $$value));
	let connected = false;
	let message = "";
	let server = persistent("GroupPlayback/server", "");
	component_subscribe($$self, server, value => $$invalidate(6, $server = value));
	let username = persistent("GroupPlayback/username", "");
	component_subscribe($$self, username, value => $$invalidate(4, $username = value));
	let password = persistent("GroupPlayback/password", "");
	component_subscribe($$self, password, value => $$invalidate(3, $password = value));
	let pub = persistent("GroupPlayback/pub", "");
	component_subscribe($$self, pub, value => $$invalidate(0, $pub = value));
	let sub = persistent("GroupPlayback/sub", "");
	component_subscribe($$self, sub, value => $$invalidate(5, $sub = value));
	let client;
	let clientId = Math.random().toString(36).substring(2);
	let pubb = "";
	console.log("clientId: ", clientId);
	let lastQueue = "";

	function publishQueue(q) {
		if (client?.isConnected() && pubb) {
			let newQueue = JSON.stringify(q.slice(0, 1000));

			if (newQueue !== lastQueue) {
				console.log("publishing queue");
				client.publish("nanote-group-playback/" + pubb + "/queue", newQueue, 1, true);
				lastQueue = newQueue;
			}
		}
	}

	function publishQueueIndex(qi) {
		if (client?.isConnected() && pubb) {
			console.log("publishing queueIndex");
			client.publish("nanote-group-playback/" + pubb + "/queueIndex", JSON.stringify(qi), 1, true);
		}
	}

	let lastPlayState = "";

	function publishPlayState(ps) {
		if (client?.isConnected() && pubb) {
			let newPlayState = JSON.stringify(ps);

			if (newPlayState !== lastPlayState) {
				console.log("publishing playState");
				client.publish("nanote-group-playback/" + pubb + "/playState", newPlayState, 1, true);
				lastPlayState = newPlayState;
			}
		}
	}

	function connect() {
		if (!($server && $username && $password)) {
			console.log("server, username, password undefined");
			return;
		}

		client = new Paho.Client($server, clientId);

		client.onConnectionLost = () => {
			$$invalidate(1, connected = false);
			$$invalidate(2, message = "Disconnected");
		};

		client.onMessageArrived = message => {
			console.log("message arrived: ", message);
			let payload = JSON.parse(message.payloadString);
			let type = message.destinationName.split("/").slice(-1)[0];

			if (type === "queue") {
				console.log("queue received");
				queue.set(payload);
			} else if (type === "queueIndex") {
				console.log("queueIndex received");
				queueIndex.set(payload);
			} else if (type === "playState") {
				console.log("playState received");
				lastPlayState = JSON.stringify(payload);
				playState.set(payload);
			}
		};

		$$invalidate(2, message = "Connecting...");

		client.connect({
			onSuccess: () => {
				$$invalidate(1, connected = true);
				$$invalidate(2, message = "Connected");
				publishQueue($queue);
				publishQueueIndex($queueIndex);
				publishPlayState($playState);

				if ($sub) {
					client.subscribe("nanote-group-playback/" + $sub + "/#");
				}
			},
			onFailure: e => {
				console.error(e);
				$$invalidate(1, connected = false);
				$$invalidate(2, message = "Faliure");
			},
			useSSL: true,
			userName: $username,
			password: $password
		});
	}

	function disconnect() {
		client.disconnect();
	}

	function input0_input_handler() {
		$server = this.value;
		server.set($server);
	}

	function input1_input_handler() {
		$username = this.value;
		username.set($username);
	}

	function input2_input_handler() {
		$password = this.value;
		password.set($password);
	}

	function input3_input_handler() {
		$sub = this.value;
		sub.set($sub);
	}

	function input4_input_handler() {
		$pub = this.value;
		pub.set($pub);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$pub*/ 1) {
			$: pubb = $pub;
		}

		if ($$self.$$.dirty & /*$queue*/ 65536) {
			$: publishQueue($queue);
		}

		if ($$self.$$.dirty & /*$queueIndex*/ 32768) {
			$: publishQueueIndex($queueIndex);
		}

		if ($$self.$$.dirty & /*$playState*/ 16384) {
			$: publishPlayState($playState);
		}
	};

	return [
		$pub,
		connected,
		message,
		$password,
		$username,
		$sub,
		$server,
		server,
		username,
		password,
		pub,
		sub,
		connect,
		disconnect,
		$playState,
		$queueIndex,
		$queue,
		input0_input_handler,
		input1_input_handler,
		input2_input_handler,
		input3_input_handler,
		input4_input_handler
	];
}

class GroupPlayback extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default GroupPlayback;