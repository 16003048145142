import './Search.svelte.css';
/* src/layouts/Search.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	empty,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_input_value,
	space,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { db, library } from "../logic/db.js";
import SongList from "../components/SongList.svelte.js";
import AlbumList from "../components/AlbumList.svelte.js";

function create_if_block(ctx) {
	let t;
	let if_block1_anchor;
	let current;
	let if_block0 = /*foundAlbums*/ ctx[2].length && create_if_block_2(ctx);
	let if_block1 = /*foundSongs*/ ctx[1].length && create_if_block_1(ctx);

	return {
		c() {
			if (if_block0) if_block0.c();
			t = space();
			if (if_block1) if_block1.c();
			if_block1_anchor = empty();
		},
		m(target, anchor) {
			if (if_block0) if_block0.m(target, anchor);
			insert(target, t, anchor);
			if (if_block1) if_block1.m(target, anchor);
			insert(target, if_block1_anchor, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (/*foundAlbums*/ ctx[2].length) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*foundAlbums*/ 4) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(t.parentNode, t);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*foundSongs*/ ctx[1].length) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*foundSongs*/ 2) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(if_block1_anchor.parentNode, if_block1_anchor);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (if_block0) if_block0.d(detaching);
			if (detaching) detach(t);
			if (if_block1) if_block1.d(detaching);
			if (detaching) detach(if_block1_anchor);
		}
	};
}

// (42:2) {#if foundAlbums.length}
function create_if_block_2(ctx) {
	let h2;
	let t1;
	let albumlist;
	let current;

	albumlist = new AlbumList({
			props: {
				maxItems: 4,
				list: /*foundAlbums*/ ctx[2] || []
			}
		});

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Albums";
			t1 = space();
			create_component(albumlist.$$.fragment);
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			mount_component(albumlist, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const albumlist_changes = {};
			if (dirty & /*foundAlbums*/ 4) albumlist_changes.list = /*foundAlbums*/ ctx[2] || [];
			albumlist.$set(albumlist_changes);
		},
		i(local) {
			if (current) return;
			transition_in(albumlist.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(albumlist.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			destroy_component(albumlist, detaching);
		}
	};
}

// (46:2) {#if foundSongs.length}
function create_if_block_1(ctx) {
	let h2;
	let t1;
	let songlist;
	let current;

	songlist = new SongList({
			props: {
				maxItems: 100,
				list: /*foundSongs*/ ctx[1] || []
			}
		});

	return {
		c() {
			h2 = element("h2");
			h2.textContent = "Songs";
			t1 = space();
			create_component(songlist.$$.fragment);
		},
		m(target, anchor) {
			insert(target, h2, anchor);
			insert(target, t1, anchor);
			mount_component(songlist, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const songlist_changes = {};
			if (dirty & /*foundSongs*/ 2) songlist_changes.list = /*foundSongs*/ ctx[1] || [];
			songlist.$set(songlist_changes);
		},
		i(local) {
			if (current) return;
			transition_in(songlist.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(songlist.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h2);
			if (detaching) detach(t1);
			destroy_component(songlist, detaching);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let input;
	let t;
	let current;
	let mounted;
	let dispose;
	let if_block = /*search*/ ctx[0] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			input = element("input");
			t = space();
			if (if_block) if_block.c();
			attr(input, "class", "searchBox svelte-7bisfm");
			attr(input, "type", "text");
			attr(input, "placeholder", "Find something");
			input.autofocus = true;
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, input);
			set_input_value(input, /*search*/ ctx[0]);
			append(div, t);
			if (if_block) if_block.m(div, null);
			current = true;
			input.focus();

			if (!mounted) {
				dispose = listen(input, "input", /*input_input_handler*/ ctx[4]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (dirty & /*search*/ 1 && input.value !== /*search*/ ctx[0]) {
				set_input_value(input, /*search*/ ctx[0]);
			}

			if (/*search*/ ctx[0]) {
				if (if_block) {
					if_block.p(ctx, dirty);

					if (dirty & /*search*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(div, null);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
			mounted = false;
			dispose();
		}
	};
}

function contains(str, substr) {
	return str.toLowerCase().indexOf(substr.toLowerCase()) !== -1;
}

function instance($$self, $$props, $$invalidate) {
	let $library;
	component_subscribe($$self, library, $$value => $$invalidate(3, $library = $$value));
	let search = "";
	let foundSongs = [];
	let foundAlbums = [];

	function input_input_handler() {
		search = this.value;
		$$invalidate(0, search);
	}

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*search, $library*/ 9) {
			$: (async (search, _) => {
				if (search) {
					$$invalidate(1, foundSongs = await db.library.filter(song => contains(song.title, search) || contains(song.artist, search)).toArray());
					$$invalidate(2, foundAlbums = await db.albums.filter(album => contains(album.album, search) || contains(album.artist, search)).toArray());
				} else {
					$$invalidate(1, foundSongs = []);
					$$invalidate(2, foundAlbums = []);
				}
			})(search, $library);
		}

		if ($$self.$$.dirty & /*foundSongs*/ 2) {
			$: console.log(foundSongs);
		}
	};

	return [search, foundSongs, foundAlbums, $library, input_input_handler];
}

class Search extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Search;