import './Modal.svelte.css';
/* src/components/Modal.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_component,
	create_slot,
	destroy_component,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	mount_component,
	safe_not_equal,
	space,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

import IconButton from "./IconButton.svelte.js";

function create_fragment(ctx) {
	let div1;
	let div0;
	let iconbutton;
	let t;
	let current;

	iconbutton = new IconButton({
			props: {
				icon: "close",
				style: "position: fixed; top: 16px; right: 16px;"
			}
		});

	iconbutton.$on("click", /*click_handler*/ ctx[4]);
	const default_slot_template = /*#slots*/ ctx[3].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[2], null);

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			create_component(iconbutton.$$.fragment);
			t = space();
			if (default_slot) default_slot.c();
			attr(div0, "class", "svelte-hw1mez");
			attr(div1, "class", "modal svelte-hw1mez");
			toggle_class(div1, "open", /*open*/ ctx[0] === /*id*/ ctx[1]);
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			mount_component(iconbutton, div0, null);
			append(div0, t);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 4)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[2],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[2])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[2], dirty, null),
						null
					);
				}
			}

			if (dirty & /*open, id*/ 3) {
				toggle_class(div1, "open", /*open*/ ctx[0] === /*id*/ ctx[1]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(iconbutton.$$.fragment, local);
			transition_in(default_slot, local);
			current = true;
		},
		o(local) {
			transition_out(iconbutton.$$.fragment, local);
			transition_out(default_slot, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(iconbutton);
			if (default_slot) default_slot.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { open } = $$props;
	let { id } = $$props;
	const click_handler = _ => $$invalidate(0, open = "");

	$$self.$$set = $$props => {
		if ('open' in $$props) $$invalidate(0, open = $$props.open);
		if ('id' in $$props) $$invalidate(1, id = $$props.id);
		if ('$$scope' in $$props) $$invalidate(2, $$scope = $$props.$$scope);
	};

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*open*/ 1) {
			$: console.log(open);
		}
	};

	return [open, id, $$scope, slots, click_handler];
}

class Modal extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { open: 0, id: 1 });
	}
}

export default Modal;