import './ServerManager.svelte.css';
/* src/layouts/ServerManager.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	destroy_each,
	detach,
	element,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	set_data,
	set_style,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out
} from "../../_snowpack/pkg/svelte/internal.js";

import { servers, db } from "../logic/db.js";
import { fade } from "../../_snowpack/pkg/svelte/transition.js";
import IconButton from "../components/IconButton.svelte.js";
import Prompt from "../components/Prompt.svelte.js";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[12] = list[i];
	child_ctx[14] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[12] = list[i];
	return child_ctx;
}

// (74:1) {#if ($servers || []).length > 0}
function create_if_block_2(ctx) {
	let div;
	let h2;
	let t1;
	let current;
	let each_value_1 = /*$servers*/ ctx[4] || [];
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	const out = i => transition_out(each_blocks[i], 1, 1, () => {
		each_blocks[i] = null;
	});

	return {
		c() {
			div = element("div");
			h2 = element("h2");
			h2.textContent = "Libraries";
			t1 = space();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(h2, "class", "svelte-17rf5tu");
			attr(div, "class", "svelte-17rf5tu");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			append(div, h2);
			append(div, t1);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*$servers, deleteServer*/ 48) {
				each_value_1 = /*$servers*/ ctx[4] || [];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
						transition_in(each_blocks[i], 1);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						transition_in(each_blocks[i], 1);
						each_blocks[i].m(div, null);
					}
				}

				group_outros();

				for (i = each_value_1.length; i < each_blocks.length; i += 1) {
					out(i);
				}

				check_outros();
			}
		},
		i(local) {
			if (current) return;

			for (let i = 0; i < each_value_1.length; i += 1) {
				transition_in(each_blocks[i]);
			}

			current = true;
		},
		o(local) {
			each_blocks = each_blocks.filter(Boolean);

			for (let i = 0; i < each_blocks.length; i += 1) {
				transition_out(each_blocks[i]);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (77:3) {#each $servers || [] as server}
function create_each_block_1(ctx) {
	let div1;
	let iconbutton;
	let t0;
	let img;
	let img_src_value;
	let t1;
	let div0;
	let h3;
	let t2_value = /*server*/ ctx[12].profile + "";
	let t2;
	let t3;
	let p;
	let t4_value = /*server*/ ctx[12].url + "";
	let t4;
	let t5;
	let current;

	function click_handler(...args) {
		return /*click_handler*/ ctx[8](/*server*/ ctx[12], ...args);
	}

	iconbutton = new IconButton({ props: { icon: "delete" } });
	iconbutton.$on("click", click_handler);

	return {
		c() {
			div1 = element("div");
			create_component(iconbutton.$$.fragment);
			t0 = space();
			img = element("img");
			t1 = space();
			div0 = element("div");
			h3 = element("h3");
			t2 = text(t2_value);
			t3 = space();
			p = element("p");
			t4 = text(t4_value);
			t5 = space();
			if (!src_url_equal(img.src, img_src_value = /*server*/ ctx[12].url + "/userImg/" + /*server*/ ctx[12].profile)) attr(img, "src", img_src_value);
			set_style(img, "border-radius", "50%");
			set_style(img, "height", "100%");
			set_style(img, "aspect-ratio", "1/1");
			attr(img, "alt", "");
			attr(h3, "class", "serverIp svelte-17rf5tu");
			attr(p, "class", "serverName svelte-17rf5tu");
			attr(div0, "class", "serverName svelte-17rf5tu");
			attr(div1, "class", "server svelte-17rf5tu");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			mount_component(iconbutton, div1, null);
			append(div1, t0);
			append(div1, img);
			append(div1, t1);
			append(div1, div0);
			append(div0, h3);
			append(h3, t2);
			append(div0, t3);
			append(div0, p);
			append(p, t4);
			append(div1, t5);
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (!current || dirty & /*$servers*/ 16 && !src_url_equal(img.src, img_src_value = /*server*/ ctx[12].url + "/userImg/" + /*server*/ ctx[12].profile)) {
				attr(img, "src", img_src_value);
			}

			if ((!current || dirty & /*$servers*/ 16) && t2_value !== (t2_value = /*server*/ ctx[12].profile + "")) set_data(t2, t2_value);
			if ((!current || dirty & /*$servers*/ 16) && t4_value !== (t4_value = /*server*/ ctx[12].url + "")) set_data(t4, t4_value);
		},
		i(local) {
			if (current) return;
			transition_in(iconbutton.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(iconbutton.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			destroy_component(iconbutton);
		}
	};
}

// (100:2) {#if newServerProfiles.length > 0}
function create_if_block_1(ctx) {
	let h40;
	let t1;
	let div;
	let t2;
	let h41;
	let t4;
	let prompt;
	let current;
	let each_value = /*newServerProfiles*/ ctx[0] || [];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	prompt = new Prompt({
			props: {
				prompt: "Enter password",
				onSubmit: /*func_1*/ ctx[11],
				submitIcon: "done",
				password: true
			}
		});

	return {
		c() {
			h40 = element("h4");
			h40.textContent = "Select a profile";
			t1 = space();
			div = element("div");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			t2 = space();
			h41 = element("h4");
			h41.textContent = "Unlock & add";
			t4 = space();
			create_component(prompt.$$.fragment);
			attr(h40, "class", "svelte-17rf5tu");
			set_style(div, "display", "flex");
			set_style(div, "flex-wrap", "wrap");
			set_style(div, "gap", "12px");
			attr(div, "class", "svelte-17rf5tu");
			attr(h41, "class", "svelte-17rf5tu");
		},
		m(target, anchor) {
			insert(target, h40, anchor);
			insert(target, t1, anchor);
			insert(target, div, anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(div, null);
			}

			insert(target, t2, anchor);
			insert(target, h41, anchor);
			insert(target, t4, anchor);
			mount_component(prompt, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			if (dirty & /*selectedProfile, newServerProfiles, newServerAddress*/ 7) {
				each_value = /*newServerProfiles*/ ctx[0] || [];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(div, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}
		},
		i(local) {
			if (current) return;
			transition_in(prompt.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(prompt.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(h40);
			if (detaching) detach(t1);
			if (detaching) detach(div);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(t2);
			if (detaching) detach(h41);
			if (detaching) detach(t4);
			destroy_component(prompt, detaching);
		}
	};
}

// (103:4) {#each newServerProfiles || [] as server, i}
function create_each_block(ctx) {
	let button;
	let img;
	let img_src_value;
	let t0;
	let div;
	let h3;
	let t1_value = /*server*/ ctx[12].user + "";
	let t1;
	let t2;
	let button_style_value;
	let mounted;
	let dispose;

	function click_handler_1(...args) {
		return /*click_handler_1*/ ctx[10](/*i*/ ctx[14], ...args);
	}

	return {
		c() {
			button = element("button");
			img = element("img");
			t0 = space();
			div = element("div");
			h3 = element("h3");
			t1 = text(t1_value);
			t2 = space();
			if (!src_url_equal(img.src, img_src_value = /*newServerAddress*/ ctx[1] + "/userImg/" + /*server*/ ctx[12].user)) attr(img, "src", img_src_value);
			set_style(img, "border-radius", "50%");
			set_style(img, "height", "100%");
			set_style(img, "aspect-ratio", "1/1");
			attr(img, "alt", "");
			attr(h3, "class", "serverIp svelte-17rf5tu");
			attr(div, "class", "serverName svelte-17rf5tu");
			attr(button, "class", "server svelte-17rf5tu");
			attr(button, "style", button_style_value = "width: unset; padding-inline-end: 16px;" + (/*selectedProfile*/ ctx[2] == /*i*/ ctx[14] && "border-left: 8px solid var(--text);"));
		},
		m(target, anchor) {
			insert(target, button, anchor);
			append(button, img);
			append(button, t0);
			append(button, div);
			append(div, h3);
			append(h3, t1);
			append(button, t2);

			if (!mounted) {
				dispose = listen(button, "click", click_handler_1);
				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*newServerAddress, newServerProfiles*/ 3 && !src_url_equal(img.src, img_src_value = /*newServerAddress*/ ctx[1] + "/userImg/" + /*server*/ ctx[12].user)) {
				attr(img, "src", img_src_value);
			}

			if (dirty & /*newServerProfiles*/ 1 && t1_value !== (t1_value = /*server*/ ctx[12].user + "")) set_data(t1, t1_value);

			if (dirty & /*selectedProfile*/ 4 && button_style_value !== (button_style_value = "width: unset; padding-inline-end: 16px;" + (/*selectedProfile*/ ctx[2] == /*i*/ ctx[14] && "border-left: 8px solid var(--text);"))) {
				attr(button, "style", button_style_value);
			}
		},
		d(detaching) {
			if (detaching) detach(button);
			mounted = false;
			dispose();
		}
	};
}

// (129:2) {#if errorText}
function create_if_block(ctx) {
	let p;
	let t;

	return {
		c() {
			p = element("p");
			t = text(/*errorText*/ ctx[3]);
			set_style(p, "color", "red");
			attr(p, "class", "svelte-17rf5tu");
		},
		m(target, anchor) {
			insert(target, p, anchor);
			append(p, t);
		},
		p(ctx, dirty) {
			if (dirty & /*errorText*/ 8) set_data(t, /*errorText*/ ctx[3]);
		},
		d(detaching) {
			if (detaching) detach(p);
		}
	};
}

function create_fragment(ctx) {
	let div1;
	let t0;
	let div0;
	let h2;
	let t2;
	let h4;
	let t4;
	let prompt;
	let t5;
	let t6;
	let current;
	let if_block0 = (/*$servers*/ ctx[4] || []).length > 0 && create_if_block_2(ctx);

	prompt = new Prompt({
			props: {
				prompt: "https://nanote.example.com",
				onSubmit: /*func*/ ctx[9]
			}
		});

	let if_block1 = /*newServerProfiles*/ ctx[0].length > 0 && create_if_block_1(ctx);
	let if_block2 = /*errorText*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div1 = element("div");
			if (if_block0) if_block0.c();
			t0 = space();
			div0 = element("div");
			h2 = element("h2");
			h2.textContent = "Add Library";
			t2 = space();
			h4 = element("h4");
			h4.textContent = "Connect to a server";
			t4 = space();
			create_component(prompt.$$.fragment);
			t5 = space();
			if (if_block1) if_block1.c();
			t6 = space();
			if (if_block2) if_block2.c();
			attr(h2, "class", "svelte-17rf5tu");
			attr(h4, "class", "svelte-17rf5tu");
			attr(div0, "class", "svelte-17rf5tu");
			attr(div1, "class", "container svelte-17rf5tu");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			if (if_block0) if_block0.m(div1, null);
			append(div1, t0);
			append(div1, div0);
			append(div0, h2);
			append(div0, t2);
			append(div0, h4);
			append(div0, t4);
			mount_component(prompt, div0, null);
			append(div0, t5);
			if (if_block1) if_block1.m(div0, null);
			append(div0, t6);
			if (if_block2) if_block2.m(div0, null);
			current = true;
		},
		p(ctx, [dirty]) {
			if ((/*$servers*/ ctx[4] || []).length > 0) {
				if (if_block0) {
					if_block0.p(ctx, dirty);

					if (dirty & /*$servers*/ 16) {
						transition_in(if_block0, 1);
					}
				} else {
					if_block0 = create_if_block_2(ctx);
					if_block0.c();
					transition_in(if_block0, 1);
					if_block0.m(div1, t0);
				}
			} else if (if_block0) {
				group_outros();

				transition_out(if_block0, 1, 1, () => {
					if_block0 = null;
				});

				check_outros();
			}

			if (/*newServerProfiles*/ ctx[0].length > 0) {
				if (if_block1) {
					if_block1.p(ctx, dirty);

					if (dirty & /*newServerProfiles*/ 1) {
						transition_in(if_block1, 1);
					}
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					transition_in(if_block1, 1);
					if_block1.m(div0, t6);
				}
			} else if (if_block1) {
				group_outros();

				transition_out(if_block1, 1, 1, () => {
					if_block1 = null;
				});

				check_outros();
			}

			if (/*errorText*/ ctx[3]) {
				if (if_block2) {
					if_block2.p(ctx, dirty);
				} else {
					if_block2 = create_if_block(ctx);
					if_block2.c();
					if_block2.m(div0, null);
				}
			} else if (if_block2) {
				if_block2.d(1);
				if_block2 = null;
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block0);
			transition_in(prompt.$$.fragment, local);
			transition_in(if_block1);
			current = true;
		},
		o(local) {
			transition_out(if_block0);
			transition_out(prompt.$$.fragment, local);
			transition_out(if_block1);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if (if_block0) if_block0.d();
			destroy_component(prompt);
			if (if_block1) if_block1.d();
			if (if_block2) if_block2.d();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $servers;
	component_subscribe($$self, servers, $$value => $$invalidate(4, $servers = $$value));

	function deleteServer(server) {
		db.servers.where({ url: server.url, profile: server.profile }).delete();
	}

	let newServerAddress = "";
	let newServerProfiles = [];
	let selectedProfile = 0;
	let errorText = "";

	async function setNewServerAddress(addr) {
		console.log(addr);
		$$invalidate(1, newServerAddress = addr);
		$$invalidate(0, newServerProfiles = []);
		$$invalidate(2, selectedProfile = 0);
		$$invalidate(3, errorText = "");

		try {
			$$invalidate(0, newServerProfiles = await (await (await fetch(newServerAddress + "/users")).json()).filter(async profile => {
				return await db.servers.where({
					url: newServerAddress,
					profile: profile.user
				}).count() == 0;
			}));
		} catch(error) {
			$$invalidate(3, errorText = "Hmm. That didn't work. Check the console for details.");
			console.error(error);
		}
	}

	async function tryConnect(pass) {
		$$invalidate(3, errorText = "");

		try {
			let authHeaders = new Headers();
			authHeaders.append("Authorization", `Basic ${btoa(newServerProfiles[selectedProfile].user + ":" + pass)}`);
			let response = await fetch(newServerAddress + "/library", { headers: authHeaders });

			if (response.status == 401) {
				$$invalidate(3, errorText = "Incorrect password.");
				return;
			}

			if (response.status != 200) {
				$$invalidate(3, errorText = "Hmm. That didn't work. Check the console for details.");
				console.error(response);
				return;
			} else {
				db.servers.add({
					url: newServerAddress,
					profile: newServerProfiles[selectedProfile].user,
					key: pass
				});

				$$invalidate(1, newServerAddress = "");
				$$invalidate(0, newServerProfiles = []);
				$$invalidate(2, selectedProfile = 0);
			}
		} catch(error) {
			$$invalidate(3, errorText = "Hmm. That didn't work. Check the console for details.");
			console.error(error);
		}
	}

	const click_handler = (server, _) => deleteServer(server);
	const func = e => setNewServerAddress(e);
	const click_handler_1 = (i, _) => $$invalidate(2, selectedProfile = i);
	const func_1 = e => tryConnect(e);

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*newServerProfiles*/ 1) {
			$: console.log(newServerProfiles);
		}
	};

	return [
		newServerProfiles,
		newServerAddress,
		selectedProfile,
		errorText,
		$servers,
		deleteServer,
		setNewServerAddress,
		tryConnect,
		click_handler,
		func,
		click_handler_1,
		func_1
	];
}

class ServerManager extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ServerManager;