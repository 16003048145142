import './Glow.svelte.css';
/* src/components/Glow.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	create_slot,
	detach,
	element,
	get_all_dirty_from_scope,
	get_slot_changes,
	init,
	insert,
	safe_not_equal,
	set_style,
	space,
	toggle_class,
	transition_in,
	transition_out,
	update_slot_base
} from "../../_snowpack/pkg/svelte/internal.js";

function create_fragment(ctx) {
	let div2;
	let div0;
	let t;
	let div1;
	let current;
	const default_slot_template = /*#slots*/ ctx[2].default;
	const default_slot = create_slot(default_slot_template, ctx, /*$$scope*/ ctx[1], null);
	const default_slot_template_1 = /*#slots*/ ctx[2].default;
	const default_slot_1 = create_slot(default_slot_template_1, ctx, /*$$scope*/ ctx[1], null);

	return {
		c() {
			div2 = element("div");
			div0 = element("div");
			if (default_slot) default_slot.c();
			t = space();
			div1 = element("div");
			if (default_slot_1) default_slot_1.c();
			attr(div0, "class", "real svelte-1qa06r1");
			attr(div1, "class", "glow svelte-1qa06r1");
			toggle_class(div1, "noMobile", /*noMobile*/ ctx[0]);
			set_style(div2, "position", "relative");
		},
		m(target, anchor) {
			insert(target, div2, anchor);
			append(div2, div0);

			if (default_slot) {
				default_slot.m(div0, null);
			}

			append(div2, t);
			append(div2, div1);

			if (default_slot_1) {
				default_slot_1.m(div1, null);
			}

			current = true;
		},
		p(ctx, [dirty]) {
			if (default_slot) {
				if (default_slot.p && (!current || dirty & /*$$scope*/ 2)) {
					update_slot_base(
						default_slot,
						default_slot_template,
						ctx,
						/*$$scope*/ ctx[1],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[1])
						: get_slot_changes(default_slot_template, /*$$scope*/ ctx[1], dirty, null),
						null
					);
				}
			}

			if (default_slot_1) {
				if (default_slot_1.p && (!current || dirty & /*$$scope*/ 2)) {
					update_slot_base(
						default_slot_1,
						default_slot_template_1,
						ctx,
						/*$$scope*/ ctx[1],
						!current
						? get_all_dirty_from_scope(/*$$scope*/ ctx[1])
						: get_slot_changes(default_slot_template_1, /*$$scope*/ ctx[1], dirty, null),
						null
					);
				}
			}

			if (dirty & /*noMobile*/ 1) {
				toggle_class(div1, "noMobile", /*noMobile*/ ctx[0]);
			}
		},
		i(local) {
			if (current) return;
			transition_in(default_slot, local);
			transition_in(default_slot_1, local);
			current = true;
		},
		o(local) {
			transition_out(default_slot, local);
			transition_out(default_slot_1, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div2);
			if (default_slot) default_slot.d(detaching);
			if (default_slot_1) default_slot_1.d(detaching);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { $$slots: slots = {}, $$scope } = $$props;
	let { noMobile = false } = $$props;

	$$self.$$set = $$props => {
		if ('noMobile' in $$props) $$invalidate(0, noMobile = $$props.noMobile);
		if ('$$scope' in $$props) $$invalidate(1, $$scope = $$props.$$scope);
	};

	return [noMobile, $$scope, slots];
}

class Glow extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { noMobile: 0 });
	}
}

export default Glow;