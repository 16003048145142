/* src/layouts/AlbumView.svelte generated by Svelte v3.44.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	handle_promise,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	set_data,
	set_style,
	space,
	src_url_equal,
	text,
	transition_in,
	transition_out,
	update_await_block_branch
} from "../../_snowpack/pkg/svelte/internal.js";

import SongList from "../components/SongList.svelte.js";
import { servers } from "../logic/db.js";
import { authString } from "../logic/utils.js";
import { db } from "../logic/db.js";

function create_catch_block_1(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (10:46)     <div     class="horizPanel"     style="align-items: flex-end; gap: 16px; flex-wrap: wrap;"    >     {#if album.coverUrl && authString($servers, album)}
function create_then_block(ctx) {
	let div1;
	let show_if;
	let t0;
	let div0;
	let h1;
	let t1_value = /*album*/ ctx[2].album + "";
	let t1;
	let t2;
	let p;
	let t3_value = /*album*/ ctx[2].artist + "";
	let t3;
	let t4;
	let t5;
	let t6_value = /*album*/ ctx[2].tracks.length + "";
	let t6;
	let t7;
	let t8;
	let promise;
	let current;

	function select_block_type(ctx, dirty) {
		if (show_if == null || dirty & /*primaryKey, $servers*/ 3) show_if = !!(/*album*/ ctx[2].coverUrl && authString(/*$servers*/ ctx[1], /*album*/ ctx[2]));
		if (show_if) return create_if_block_2;
		return create_else_block_1;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block0 = current_block_type(ctx);
	let if_block1 = /*album*/ ctx[2].year && create_if_block_1(ctx);

	function select_block_type_1(ctx, dirty) {
		if (/*album*/ ctx[2].tracks.length == 1) return create_if_block;
		return create_else_block;
	}

	let current_block_type_1 = select_block_type_1(ctx, -1);
	let if_block2 = current_block_type_1(ctx);

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: false,
		pending: create_pending_block_1,
		then: create_then_block_1,
		catch: create_catch_block,
		value: 3,
		blocks: [,,,]
	};

	handle_promise(promise = db.library.bulkGet(/*album*/ ctx[2].tracks), info);

	return {
		c() {
			div1 = element("div");
			if_block0.c();
			t0 = space();
			div0 = element("div");
			h1 = element("h1");
			t1 = text(t1_value);
			t2 = space();
			p = element("p");
			t3 = text(t3_value);
			t4 = space();
			if (if_block1) if_block1.c();
			t5 = text("\r\n\t\t\t\t\t• ");
			t6 = text(t6_value);
			t7 = space();
			if_block2.c();
			t8 = space();
			info.block.c();
			attr(div0, "class", "vertiPanel");
			set_style(div0, "flex", "1");
			set_style(div0, "min-width", "min(360px, 100%)");
			attr(div1, "class", "horizPanel");
			set_style(div1, "align-items", "flex-end");
			set_style(div1, "gap", "16px");
			set_style(div1, "flex-wrap", "wrap");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			if_block0.m(div1, null);
			append(div1, t0);
			append(div1, div0);
			append(div0, h1);
			append(h1, t1);
			append(div0, t2);
			append(div0, p);
			append(p, t3);
			append(p, t4);
			if (if_block1) if_block1.m(p, null);
			append(p, t5);
			append(p, t6);
			append(p, t7);
			if_block2.m(p, null);
			append(div1, t8);
			info.block.m(div1, info.anchor = null);
			info.mount = () => div1;
			info.anchor = null;
			current = true;
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block0) {
				if_block0.p(ctx, dirty);
			} else {
				if_block0.d(1);
				if_block0 = current_block_type(ctx);

				if (if_block0) {
					if_block0.c();
					if_block0.m(div1, t0);
				}
			}

			if ((!current || dirty & /*primaryKey*/ 1) && t1_value !== (t1_value = /*album*/ ctx[2].album + "")) set_data(t1, t1_value);
			if ((!current || dirty & /*primaryKey*/ 1) && t3_value !== (t3_value = /*album*/ ctx[2].artist + "")) set_data(t3, t3_value);

			if (/*album*/ ctx[2].year) {
				if (if_block1) {
					if_block1.p(ctx, dirty);
				} else {
					if_block1 = create_if_block_1(ctx);
					if_block1.c();
					if_block1.m(p, t5);
				}
			} else if (if_block1) {
				if_block1.d(1);
				if_block1 = null;
			}

			if ((!current || dirty & /*primaryKey*/ 1) && t6_value !== (t6_value = /*album*/ ctx[2].tracks.length + "")) set_data(t6, t6_value);

			if (current_block_type_1 !== (current_block_type_1 = select_block_type_1(ctx, dirty))) {
				if_block2.d(1);
				if_block2 = current_block_type_1(ctx);

				if (if_block2) {
					if_block2.c();
					if_block2.m(p, null);
				}
			}

			info.ctx = ctx;

			if (dirty & /*primaryKey*/ 1 && promise !== (promise = db.library.bulkGet(/*album*/ ctx[2].tracks)) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div1);
			if_block0.d();
			if (if_block1) if_block1.d();
			if_block2.d();
			info.block.d();
			info.token = null;
			info = null;
		}
	};
}

// (25:3) {:else}
function create_else_block_1(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = "album.svg")) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "class", "albumCover");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (15:3) {#if album.coverUrl && authString($servers, album)}
function create_if_block_2(ctx) {
	let img;
	let img_src_value;

	return {
		c() {
			img = element("img");
			if (!src_url_equal(img.src, img_src_value = /*album*/ ctx[2].server + /*album*/ ctx[2].coverUrl + "?auth=" + authString(/*$servers*/ ctx[1], /*album*/ ctx[2]))) attr(img, "src", img_src_value);
			attr(img, "alt", "");
			attr(img, "loading", "lazy");
			set_style(img, "width", "min(100%, 360px)");
			set_style(img, "border-radius", "8px");
		},
		m(target, anchor) {
			insert(target, img, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*primaryKey, $servers*/ 3 && !src_url_equal(img.src, img_src_value = /*album*/ ctx[2].server + /*album*/ ctx[2].coverUrl + "?auth=" + authString(/*$servers*/ ctx[1], /*album*/ ctx[2]))) {
				attr(img, "src", img_src_value);
			}
		},
		d(detaching) {
			if (detaching) detach(img);
		}
	};
}

// (32:5) {#if album.year}
function create_if_block_1(ctx) {
	let t0;
	let t1_value = /*album*/ ctx[2].year + "";
	let t1;

	return {
		c() {
			t0 = text("• ");
			t1 = text(t1_value);
		},
		m(target, anchor) {
			insert(target, t0, anchor);
			insert(target, t1, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*primaryKey*/ 1 && t1_value !== (t1_value = /*album*/ ctx[2].year + "")) set_data(t1, t1_value);
		},
		d(detaching) {
			if (detaching) detach(t0);
			if (detaching) detach(t1);
		}
	};
}

// (34:39) {:else}
function create_else_block(ctx) {
	let t;

	return {
		c() {
			t = text("songs");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (34:5) {#if album.tracks.length == 1}
function create_if_block(ctx) {
	let t;

	return {
		c() {
			t = text("song");
		},
		m(target, anchor) {
			insert(target, t, anchor);
		},
		d(detaching) {
			if (detaching) detach(t);
		}
	};
}

// (1:0) <script>   import SongList from "../components/SongList.svelte";   import { servers }
function create_catch_block(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (37:55)       <SongList list={songs}
function create_then_block_1(ctx) {
	let songlist;
	let current;
	songlist = new SongList({ props: { list: /*songs*/ ctx[3] } });

	return {
		c() {
			create_component(songlist.$$.fragment);
		},
		m(target, anchor) {
			mount_component(songlist, target, anchor);
			current = true;
		},
		p(ctx, dirty) {
			const songlist_changes = {};
			if (dirty & /*primaryKey*/ 1) songlist_changes.list = /*songs*/ ctx[3];
			songlist.$set(songlist_changes);
		},
		i(local) {
			if (current) return;
			transition_in(songlist.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(songlist.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(songlist, detaching);
		}
	};
}

// (1:0) <script>   import SongList from "../components/SongList.svelte";   import { servers }
function create_pending_block_1(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

// (1:0) <script>   import SongList from "../components/SongList.svelte";   import { servers }
function create_pending_block(ctx) {
	return {
		c: noop,
		m: noop,
		p: noop,
		i: noop,
		o: noop,
		d: noop
	};
}

function create_fragment(ctx) {
	let div;
	let promise;
	let current;

	let info = {
		ctx,
		current: null,
		token: null,
		hasCatch: false,
		pending: create_pending_block,
		then: create_then_block,
		catch: create_catch_block_1,
		value: 2,
		blocks: [,,,]
	};

	handle_promise(promise = db.albums.get(/*primaryKey*/ ctx[0]), info);

	return {
		c() {
			div = element("div");
			info.block.c();
			attr(div, "class", "vertiPanel");
			set_style(div, "padding", "12px");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			info.block.m(div, info.anchor = null);
			info.mount = () => div;
			info.anchor = null;
			current = true;
		},
		p(new_ctx, [dirty]) {
			ctx = new_ctx;
			info.ctx = ctx;

			if (dirty & /*primaryKey*/ 1 && promise !== (promise = db.albums.get(/*primaryKey*/ ctx[0])) && handle_promise(promise, info)) {
				
			} else {
				update_await_block_branch(info, ctx, dirty);
			}
		},
		i(local) {
			if (current) return;
			transition_in(info.block);
			current = true;
		},
		o(local) {
			for (let i = 0; i < 3; i += 1) {
				const block = info.blocks[i];
				transition_out(block);
			}

			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			info.block.d();
			info.token = null;
			info = null;
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $servers;
	component_subscribe($$self, servers, $$value => $$invalidate(1, $servers = $$value));
	let { primaryKey } = $$props;

	$$self.$$set = $$props => {
		if ('primaryKey' in $$props) $$invalidate(0, primaryKey = $$props.primaryKey);
	};

	return [primaryKey, $servers];
}

class AlbumView extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { primaryKey: 0 });
	}
}

export default AlbumView;